<template>
  <v-chip
    small
    :class="tile ? 'rounded-r-0' : ''"
    :color="color + ' darken-2'"
    @click="expand = !expand"
    @blur="expand = false"
    class="px-2"
    >
    <v-icon 
      color="white" 
      size="18">
      {{ icon }}
    </v-icon>
    <v-expand-x-transition>
      <span
        class="white--text ml-2"
        v-if="expand"
        >
        {{ text }}
      </span>
    </v-expand-x-transition>
  </v-chip>
</template>

<script>
export default {
  data () {
    return {
      expand: false,
    }
  },

  created () {
    this.expand = this.expanded
  },

  props: {
    text: {
      required: true,
      type: String
    },
    color: {
      required: true,
      type: String
    },
    icon: {
      required: true,
      type: String
    },
    expanded: {
      required: false,
      type: Boolean,
      default: false
    },
    tile: {
      required: false,
      type: Boolean,
      default: false
    },
  }
}
</script>
